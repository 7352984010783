import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';

import './BillingCustomerInformation.scss';

export default function BillingCustomerInformation({ selectedPartnerToken }) {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [partnerToken, setPartnerToken] = useState("")

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ partnertype: '', contractperiod: '', contractstatus: '', currentmonthusage: '', downpayment: '', downpaymentduedate: '', subscriptionplan: '' });
    const formDataRef = useRef(null);
    const [formData1, setformData1] = useState({ startDate: '', endDate: '' });
    const formDataRef1 = useRef(null);
    const [loadingExport, setLoadingExport] = useState(false);



    const onSubmitExport = async (e) => {
        e.preventDefault();
        // console.log("dsadasda", formData)

        setLoadingExport(true);

        var startDateString = formData1.startDate.getFullYear() + '-' + (((formData1.startDate.getMonth()+1) < 10) ? ('0' + (formData1.startDate.getMonth()+1)) : (formData1.startDate.getMonth()+1)) + '-' + (((formData1.startDate.getDate()) < 10) ? ('0' + (formData1.startDate.getDate())) : (formData1.startDate.getDate())) + 'T00:00:00.000Z'
        var endDateString = formData1.endDate.getFullYear() + '-' + (((formData1.endDate.getMonth()+1) < 10) ? ('0' + (formData1.endDate.getMonth()+1)) : (formData1.endDate.getMonth()+1)) + '-' + (((formData1.endDate.getDate()) < 10) ? ('0' + (formData1.endDate.getDate())) : (formData1.endDate.getDate())) + 'T23:59:59.000Z'

        try {
            const response = await axios.post(backendlink + 'vitome/exportbillinginfo',
            // const response = await axios.post('http://localhost:7777/vitome/exportbillinginfo',
            {
                startdate: startDateString,
                enddate: endDateString,
                partnertoken: selectedPartnerToken
            }, 
            {
                headers: {
                    token: user.token
                },
                responseType: 'blob' // set the response type to blob 
            }).then((res)=>{
                console.log(res)

                setLoadingExport(false);

                var currentDate = new Date();
                var currentDateString = currentDate.getFullYear() + (((currentDate.getMonth()+1) < 10) ? ('0' + (currentDate.getMonth()+1)) : (currentDate.getMonth()+1)) + (((currentDate.getDate()) < 10) ? ('0' + (currentDate.getDate())) : (currentDate.getDate()))
                var fileName = 'vitomeportal_Billing_' + currentDateString;

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName + '.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            setLoadingExport(false);

            console.log(error);
            alert(error);
        }
    }


    useEffect(() => {
        if (selectedPartnerToken == null) {
            navigate('/');
        } else {
            setPartnerToken(selectedPartnerToken);

            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'vitome/getpartnerbillinginfo',
                        {
                            partnertoken: selectedPartnerToken
                        },
                        {
                            headers: {
                                token: user.token
                            }
                        });

                    if (response.data.errorno == 0) {
                        // console.log(response.data.result)
                        console.log("SSSSSSSSSSSSSSSS", response)

                        const outputArray = response.data.result.map(item => {
                            // console.log(item)

                            var usertypeString = item.usertype

                            switch (item.usertype) {
                                case "CP":
                                    usertypeString = "Channel Partner"
                                    break;

                                case "DIST":
                                    usertypeString = "Distributor"
                                    break;

                                default:
                                    break;
                            }

                            var downpaymentString = item.downpayment;

                            switch (item.downpayment) {
                                case 0:
                                    downpaymentString = "Paid"
                                    break;

                                case 1:
                                    downpaymentString = "Not Paid"
                                    break;

                                default:
                                    break;
                            }

                            let subscriptionPlan = item.subscriptionplan;
                            let maxTransactionsPerMonth = item.maxTransactionsPerMonth;
                            let tierName = item.tierName;
                            let billingPricingPerMonth = item.billingPricingPerMonth;
                            let formattedString = `Tier ${tierName} ${subscriptionPlan} (${maxTransactionsPerMonth}) ${billingPricingPerMonth} / month`;

                            let successCount = item.successCount;
                            let failureCount = item.failureCount;
                            let count = successCount + Math.floor(failureCount/5);
                            let formattedString2 = `${count} / ${maxTransactionsPerMonth}`;

                            // let contractStartDate = new Date(item.contractStartDate);
                            // let contractEndDate = new Date(item.contractEndDate);
                            let contractStartDate = new Date(item.contractStartDate.split('T')[0]);
                            let contractEndDate = new Date(item.contractEndDate.split('T')[0]);

                            let contractStartString = contractStartDate.getFullYear() + '-' + (((contractStartDate.getMonth()+1) <= 10) ? ('0' + (contractStartDate.getMonth()+1)) : (contractStartDate.getMonth()+1)) + '-' + (((contractStartDate.getDate()) <= 10) ? ('0' + (contractStartDate.getDate())) : (contractStartDate.getDate()));
                            let contractEndString = contractEndDate.getFullYear() + '-' + (((contractEndDate.getMonth()+1) <= 10) ? ('0' + (contractEndDate.getMonth()+1)) : (contractEndDate.getMonth()+1)) + '-' + (((contractEndDate.getDate()) <= 10) ? ('0' + (contractEndDate.getDate())) : (contractEndDate.getDate()));

                            // let formattedString3 = `${contractStartDate.getFullYear()}-${contractStartDate.getMonth() + 1}-${contractStartDate.getDate()} to ${contractEndDate.getFullYear()}-${contractEndDate.getMonth() + 1}-${contractEndDate.getDate()}`
                            let formattedString3 = contractStartString + ' to ' + contractEndString

                            let downpaymentDueDate = new Date(item.downpaymentDueDate);
                            // let readableDate = `${downpaymentDueDate.getFullYear()}-${downpaymentDueDate.getMonth() +1}-${downpaymentDueDate.getDate()}`;
                            let readableDate = downpaymentDueDate.getFullYear() + '-' + (((downpaymentDueDate.getMonth()+1) <= 10) ? ('0' + (downpaymentDueDate.getMonth()+1)) : (downpaymentDueDate.getMonth()+1)) + '-' + (((downpaymentDueDate.getDate()) <= 10) ? ('0' + (downpaymentDueDate.getDate())) : (downpaymentDueDate.getDate()));

                            setformData({
                                partnertype: item.usertype,
                                contractperiod: formattedString3,
                                currentmonthusage: formattedString2,
                                downpayment: downpaymentString,
                                downpaymentduedate: readableDate,
                                contractstatus: item.contractStatus,
                                subscriptionplan: formattedString,

                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();

            // var dateStartdate = new Date(Date.now() - 86400000)
            // var dateEnddate = new Date()
            // var startDateString = dateStartdate.getFullYear() + '-' + (((dateStartdate.getMonth()+1) <= 10) ? ('0' + (dateStartdate.getMonth()+1)) : (dateStartdate.getMonth()+1)) + '-' + (((dateStartdate.getDate()) <= 10) ? ('0' + (dateStartdate.getDate())) : (dateStartdate.getDate()));
            // var endDateString = dateEnddate.getFullYear() + '-' + (((dateEnddate.getMonth()+1) <= 10) ? ('0' + (dateEnddate.getMonth()+1)) : (dateEnddate.getMonth()+1)) + '-' + (((dateEnddate.getDate()) <= 10) ? ('0' + (dateEnddate.getDate())) : (dateEnddate.getDate()));
    
            // setformData1({ startDate: startDateString, endDate: endDateString })

            var dateStartdate = new Date(Date.now() - 86400000)
            var dateEnddate = new Date()
    
            setformData1({ startDate: dateStartdate, endDate: dateEnddate })
        }
    }, [selectedPartnerToken]);

    const readonlyptions = { readOnly: 'true' };
    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    return (
        <React.Fragment>
        <Form formData={formData} ref={formDataRef} disabled={false}>

            <Item
                dataField={'partnertype'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Partner Type' />
            </Item>

            <Item
                dataField={'contractperiod'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Contract Period' />
            </Item>

            <Item
                dataField={'contractstatus'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Contract Status' />
            </Item>

            <Item
                dataField={'currentmonthusage'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Current Month Usage' />
            </Item>

            <Item
                dataField={'downpayment'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Down Payment' />
            </Item>

            <Item
                dataField={'downpaymentduedate'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Down Payment Due Date' />
            </Item>

            <Item
                dataField={'subscriptionplan'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Subscribed Plan' />
            </Item>

        </Form>
        <form onSubmit={onSubmitExport}>
                <Form formData={formData1} ref={formDataRef1} disabled={false} colCount={3}>

                    <Item
                        dataField={'startDate'}
                        editorType={'dxDateBox'}
                        editorOptions={dateoptions}
                    >
                        <Label text='Start Date' />
                    </Item>

                    <Item
                        dataField={'endDate'}
                        editorType={'dxDateBox'}
                        editorOptions={dateoptions}
                    >
                        <Label text='End Date' />
                    </Item>

                    <ButtonItem
                        marginTop={'auto'}
                        marginBottom={'0px'}
                        style={"margin-top: auto; margin-bottom: 0px"}
                        width={'100%'}
                    >
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            useSubmitBehavior={true}
                        >
                            <span className="dx-button-text">
                            {
                                loadingExport
                                ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                : ('Export')
                            }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </form>
        </React.Fragment>
    )
}