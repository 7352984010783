import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import { PartnerListDatagrid, PatientListDatagrid } from '../../components';

export default function AdminPatient() {
    const navigate = useNavigate();
    const location = useLocation();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(1);
    
    const [customerToken, setCustomerToken] = useState("")
    const [linkedPartner, setLinkedPartner] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [customerID, setCustomerID] = useState("")
    const [doctorToken, setDoctorToken] = useState("")
    const [doctorName, setDoctorName] = useState("")

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;
        var isReselected = false;

        if (itemIndex == tabSelectedIndex) {
            isReselected = true
        }

        setTabSelectedIndex(itemIndex)

        if (itemIndex == 1 && isReselected == true) {
            navigate('/admin-partner?tabSelectedIndex=1');
        }
    }

    useEffect(() => {
        // console.log(location.state)

        if (location.state?.customerToken == null || location.state?.linkedPartner == null || location.state?.customerName == null || location.state?.customerID == null || location.state?.doctorToken == null || location.state?.doctorName == null) {
            navigate('/');
        } else {
            setCustomerToken(location.state.customerToken);
            setLinkedPartner(location.state.linkedPartner);
            setCustomerName(location.state.customerName);
            setCustomerID(location.state.customerID);
            setDoctorToken(location.state.doctorToken);
            setDoctorName(location.state.doctorName);
        }
    }, [customerToken, linkedPartner, customerName, customerID, doctorToken, doctorName]);

    return (
        <React.Fragment>
            <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true} defaultSelectedIndex={1} onTitleClick={onTitleClick}>
                <Item title="Partner" icon="floppy"> 
                    <PartnerListDatagrid />
                </Item>

                <Item title="Customer" icon="floppy"> 
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Partner:</th>
                                <td>{location.state.linkedPartner}</td>
                                <th>Customer:</th>
                                <td>{location.state.customerName}</td>
                                <th>Customer ID:</th>
                                <td>{location.state.customerID}</td>
                                <th>Doctor:</th>
                                <td>{location.state.doctorName}</td>
                            </tr>
                        </thead>
                    </table>
                    
                    <PatientListDatagrid />
                </Item>
            </TabPanel>
        </React.Fragment>
      );
  }