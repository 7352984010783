import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import { PartnerEditForm, CustomerListDatagrid } from '../../components';

export default function PartnerEditPage() {
    const navigate = useNavigate();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;
        var isReselected = false;

        if (itemIndex == tabSelectedIndex) {
            isReselected = true
        }

        setTabSelectedIndex(itemIndex)

        if (itemIndex == 0 && isReselected == true) {
            navigate('/admin-partner');
        }
    }

    return (
        <React.Fragment>
            <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true} onTitleClick={onTitleClick}>
                <Item title="Partner" icon="floppy"> 
                    <PartnerEditForm />
                </Item>

                <Item title="Customer" icon="floppy"> 
                    <CustomerListDatagrid />
                </Item>
            </TabPanel>
        </React.Fragment>
      );
  }