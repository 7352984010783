import React, { useState, useEffect } from 'react';
import { Button } from 'devextreme-react/button';
import { backendlink } from '../../config';
import axios from 'axios';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useAuth } from '../../contexts/auth';
import { SelectBox } from 'devextreme-react';
import { BillingCustomerInformation } from '../../components';



export default function BillingPage() {

    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);


    const { user } = useAuth();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.post(backendlink + 'vitome/getallpartner', {}, {
                    headers: {
                        token: user.token
                    }
                });
                setPartners(result.data.result[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSelectionChange = (e) => {
        setSelectedPartner(e.value);
    };

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Billing Overview</h2>

            <div className={'content-block dx-card responsive-paddings'}>
            <h6>Customer Information</h6>

            <div className="dx-field">
          <div className="dx-field-label">Selected Partner</div>
          <div className="dx-field-value">
          <SelectBox
                    dataSource={partners}
                    displayExpr="companyname"
                    valueExpr="userToken"
                    placeholder="Select a partner"
                    onValueChanged={handleSelectionChange}
                />
          </div>
        </div>


                {selectedPartner && <div>
                    <BillingCustomerInformation selectedPartnerToken={selectedPartner} />
                </div>}
            </div>
        </React.Fragment>
    )
}
