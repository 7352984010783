import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './DoctorListDatagrid.css';

export default function DoctorListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const location = useLocation();

    const [formData, setformData] = useState({ startDate: '', endDate: '' });
    const formDataRef = useRef(null)

    const [loadingExport, setLoadingExport] = useState(false);

    function handleRowClick(e) {
        const customerToken = location.state.customerToken;
        const linkedPartner = location.state.linkedPartner;
        const customerName = location.state.customerName;
        const customerID = location.state.customerID;

        const doctorToken = e.data.userToken;
        const doctorName = e.data.doctorName;

        navigate('/admin-patient', { state: { customerToken: customerToken, linkedPartner: linkedPartner, customerName: customerName, customerID: customerID, doctorToken: doctorToken, doctorName: doctorName } });
    }

    const onSubmitExport = async (e) => {
        e.preventDefault();
        // console.log("dsadasda", formData)
        
        var arrUserList = [];

        users.forEach(user => {
            arrUserList.push(user.userToken);
        });

        // console.log(arrUserList)

        setLoadingExport(true);

        var startDateString = formData.startDate.getFullYear() + '-' + (((formData.startDate.getMonth()+1) < 10) ? ('0' + (formData.startDate.getMonth()+1)) : (formData.startDate.getMonth()+1)) + '-' + (((formData.startDate.getDate()) < 10) ? ('0' + (formData.startDate.getDate())) : (formData.startDate.getDate())) + 'T00:00:00.000Z'
        var endDateString = formData.endDate.getFullYear() + '-' + (((formData.endDate.getMonth()+1) < 10) ? ('0' + (formData.endDate.getMonth()+1)) : (formData.endDate.getMonth()+1)) + '-' + (((formData.endDate.getDate()) < 10) ? ('0' + (formData.endDate.getDate())) : (formData.endDate.getDate())) + 'T23:59:59.000Z'

        try {
            const response = await axios.post(backendlink + 'vitome/exportpatientlog',
            {
                startdate: startDateString,
                enddate: endDateString,
                usertype: "Doctor",
                userlist: arrUserList
            }, 
            {
                headers: {
                    token: user.token
                },
                responseType: 'blob' // set the response type to blob 
            }).then((res)=>{
                console.log(res)

                setLoadingExport(false);

                var currentDate = new Date();
                var currentDateString = currentDate.getFullYear() + (((currentDate.getMonth()+1) < 10) ? ('0' + (currentDate.getMonth()+1)) : (currentDate.getMonth()+1)) + (((currentDate.getDate()) < 10) ? ('0' + (currentDate.getDate())) : (currentDate.getDate()))
                var fileName = 'vitomeportal_Log_' + currentDateString;

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName + '.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            setLoadingExport(false);

            console.log(error);
            alert(error);
        }
    }

    useEffect(() => {
        // console.log(user.token)
        // console.log(user.userToken)
        // console.log(user.accessToken)

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'vitome/getalldoctor',
                {
                    customertoken: location.state.customerToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    // console.log(response.data.result)

                    const outputArray = response.data.result[0].map(item => {
                        var dateCreatedAt = new Date(item.createdAt)
                        var createdAtString = dateCreatedAt.getFullYear() + '-' + (((dateCreatedAt.getMonth()+1) <= 10) ? ('0' + (dateCreatedAt.getMonth()+1)) : (dateCreatedAt.getMonth()+1)) + '-' + (((dateCreatedAt.getDate()) <= 10) ? ('0' + (dateCreatedAt.getDate())) : (dateCreatedAt.getDate()));

                        var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                        return { doctorName: item.name, description: item.description0, status: statusString, phone: item.phone, createdAt: createdAtString, address: item.companyaddress, userToken: item.userToken };
                    });
                    setUsers(outputArray)
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

        var dateStartdate = new Date(Date.now() - 86400000)
        var dateEnddate = new Date()

        setformData({ startDate: dateStartdate, endDate: dateEnddate })
    }, [user]);

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    return (
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClick}>
                <Column dataField='doctorName' caption='Doctor Name' />
                <Column dataField='description' caption='Description' />
                <Column dataField='status' caption='Status' />
                <Column dataField='phone' caption='Phone Number' />
                <Column dataField='createdAt' caption='Created At' />
                <Column dataField='address' caption='Address' />

                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>

            <form onSubmit={onSubmitExport}>
                <Form formData={formData} ref={formDataRef} disabled={false} colCount={3}>

                    <Item
                        dataField={'startDate'}
                        editorType={'dxDateBox'}
                        editorOptions={dateoptions}
                    >
                        <Label text='Start Date' />
                    </Item>

                    <Item
                        dataField={'endDate'}
                        editorType={'dxDateBox'}
                        editorOptions={dateoptions}
                    >
                        <Label text='End Date' />
                    </Item>

                    <ButtonItem
                        marginTop={'auto'}
                        marginBottom={'0px'}
                        style={"margin-top: auto; margin-bottom: 0px"}
                        width={'100%'}
                    >
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            useSubmitBehavior={true}
                        >
                            <span className="dx-button-text">
                            {
                                loadingExport
                                ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                : ('Export')
                            }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </form>
        </React.Fragment>
    )

}