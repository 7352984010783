export const navigationSuperAdmin = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Partner',
        path: '/admin-partner',
        icon: 'partner'
    },
    {
        text: 'Billing',
        path: '/admin-billing',
        icon: 'billing'
    },
    {
        text: 'Edit History',
        path: '/edit-history',
        icon: 'history'
    },
    {
        text: 'Add Partner',
        path: '/partner-add',
        icon: 'add'
    },
    {
        text: 'Add Admin',
        path: '/admin-add',
        icon: 'add'
    }
];

export const navigationAdmin = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Partner',
        path: '/admin-partner',
        icon: 'partner'
    },
    {
        text: 'Billing',
        path: '/admin-billing',
        icon: 'billing'
    },
    {
        text: 'Add Partner',
        path: '/partner-add',
        icon: 'add'
    }
];