import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import { PartnerListDatagrid, CustomerListDatagrid } from '../../components';

export default function AdminPartner() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
    // const [tabDefaultSelectedIndex, setTabDefaultSelectedIndex] = useState(0);

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;

        setTabSelectedIndex(itemIndex)
    }

    useEffect(() => {
        var searchParamTabSelectedIndex = searchParams.get('tabSelectedIndex')

        if (searchParamTabSelectedIndex != null) {
            // setTabDefaultSelectedIndex(searchParamTabSelectedIndex)
            setTabSelectedIndex(searchParamTabSelectedIndex)
        }
    }, []);

    return (
        <React.Fragment>
            <h2 className={'content-block'}>{(tabSelectedIndex == 0 ? 'Partner Overview' : 'Customer Overview')}</h2>

            <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true} selectedIndex={tabSelectedIndex} onTitleClick={onTitleClick}>
                <Item title="Partner" icon="floppy"> 
                    <PartnerListDatagrid />
                </Item>

                <Item title="Customer" icon="floppy"> 
                    <CustomerListDatagrid />
                </Item>
            </TabPanel>
        </React.Fragment>
      );
  }