import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, json, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

import './PartnerInfoList.scss';

export default function PartnerInfoList() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [partnerToken, setPartnerToken] = useState("")

    const location = useLocation();

    const [loadingContractAgreement, setLoadingContractAgreement] = useState(false);
    const [loadingPricingAgreement, setLoadingPricingAgreement] = useState(false);
    const [loadingEvaluationAndServiceAgreement, setLoadingEvaluationAndServiceAgreement] = useState(false);
    const [loadingQuotation, setLoadingQuotation] = useState(false);
    const [loadingPo, setLoadingPo] = useState(false);

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ id: '', username: '', companyname: '', companyaddress: '', email: '', phone: '', subscriptionplan: '', startDate: '', endDate: '', contactperson: '', usertype: '', downpaymentreceived: '' });
    const formDataRef = useRef(null)

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    function pressEdit() {
        navigate('/partner-edit', { state: { partnerToken: partnerToken } });
    }

    function pressChangePassword() {
        navigate('/partner-changepassword', { state: { partnerToken: partnerToken } });
    }

    function setSubLoading(item, value) {
        switch (item) {
            case 'contractAgreement':
                setLoadingContractAgreement(value)
                break;

            case 'pricingAgreement':
                setLoadingPricingAgreement(value)
                break;

            case 'evaluationAndServiceAgreement':
                setLoadingEvaluationAndServiceAgreement(value)
                break;

            case 'quotation':
                setLoadingQuotation(value)
                break;

            case 'po':
                setLoadingPo(value)
                break;
        
            default:
                break;
        }
    }

    const downloadFile = async (item) => {
        setSubLoading(item, true)

        // console.log(user.token)
        // console.log(partnerToken)
        // console.log(item)

        try {
            const response = await axios.post(backendlink + '/vitome/downloaddocument',
            {
                partnertoken: partnerToken,
                document: item
            }, 
            {
                headers: {
                    token: user.token
                },
                responseType: 'blob' // set the response type to blob 
            }).then(async (res)=>{
                console.log(res)

                setSubLoading(item, false);

                const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
                const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
        
                // console.log(responseJson)

                if (responseJson.type == "application/json;charset=utf-8") {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                        const textDecoder = new TextDecoder();
                        const decodedText = textDecoder.decode(event.target.result);
                        const jsonData = JSON.parse(decodedText);

                        // console.log(jsonData)

                        // if (jsonData.errCode) {
                        //     if (jsonData.errCode == 500) {
                                // setToastConfig({
                                //     ...toastConfig,
                                //     isVisible: true,
                                //     type: 'error',
                                //     message: "File not exists",
                                // }); 
                        //     } else {
                        //         setToastConfig({
                        //             ...toastConfig,
                        //             isVisible: true,
                        //             type: 'error',
                        //             message: res.data.errmessage,
                        //         }); 
                        //     }
                        // } else {
                        //     setToastConfig({
                        //         ...toastConfig,
                        //         isVisible: true,
                        //         type: 'error',
                        //         message: 'Decode error',
                        //     }); 
                        // }

                        //Due to try catch issue from backend, now all errors will be assumed as file not found (assumed to be fixed next phase)
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'error',
                            message: "File not exists",
                        }); 
                    };
                    reader.readAsArrayBuffer(responseJson);
                } else {
                    // console.log(responseJson.type)

                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            });
        } catch (error) {
            setSubLoading(item, false);

            console.log(error);
            alert(error);
        }
    }

    useEffect(() => {
        if (location.state?.partnerToken == null) {
            navigate('/');
        } else {
            setPartnerToken(location.state.partnerToken);

            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'vitome/getpartner',
                    {
                        partnertoken: location.state.partnerToken
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });

                    if (response.data.errorno == 0) {
                        console.log(response.data.result)

                        const outputArray = response.data.result.map(item => {
                            // console.log(item.id)

                            var usertypeString = item.usertype

                            switch (item.usertype) {
                                case "CP":
                                    usertypeString = "Channel Partner"
                                    break;

                                case "DIST":
                                    usertypeString = "Distributor"
                                    break;
                            
                                default:
                                    break;
                            }

                            var downpaymentString = item.downpaymentStatus;

                            switch (item.downpaymentStatus) {
                                case "0":
                                    downpaymentString = "Not Paid"
                                    break;

                                case "1":
                                    downpaymentString = "Paid"
                                    break;

                                default:
                                    break;
                            }

                            setformData({
                                id: item.id, 
                                username: item.username, 
                                companyname: item.companyname, 
                                companyaddress: item.companyaddress, 
                                email: item.email, 
                                phone: item.phone, 
                                subscriptionplan: item.subscriptionplan, 
                                startDate: item.contractStartDate.split('T')[0],
                                endDate: item.contractEndDate.split('T')[0],
                                contactperson: item.contactperson,
                                usertype: usertypeString, 
                                userToken: item.userToken,
                                downpaymentreceived: downpaymentString,
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();
        }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };
    const readonlyDateOptions = { readOnly: 'true', displayFormat: 'yyyy-MM-dd' };

    return (
        <form>
            <Form formData={formData} ref={formDataRef} disabled={false}>
                <GroupItem cssClass="form-group" colCount={2}>
                    <ButtonItem>
                        <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        onClick={pressEdit}
                        >
                        <span className="dx-button-text">
                            Edit
                        </span>
                        </ButtonOptions>
                    </ButtonItem>

                    <ButtonItem>
                        <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        onClick={pressChangePassword}
                        >
                        <span className="dx-button-text">
                            Change Password
                        </span>
                        </ButtonOptions>
                    </ButtonItem>
                </GroupItem>

                <Item
                    dataField={'id'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Partner ID' />
                </Item>

                <Item
                    dataField={'usertype'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Partner Type' />
                </Item>

                <Item
                    dataField={'companyname'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Customer Name' />
                </Item>

                <Item
                    dataField={'companyaddress'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Company Address' />
                </Item>

                <Item
                    dataField={'contactperson'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Contact Person' />
                </Item>

                <Item
                    dataField={'phone'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Phone Number' />
                </Item>

                <Item
                    dataField={'email'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Contact Email' />
                </Item>

                <Item
                    dataField={'subscriptionplan'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Subscription Plan' />
                </Item>

                {/* <Item
                    dataField={'contractperiod'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Contract Period' />
                </Item> */}
                    <Item
                        dataField={'startDate'}
                        editorType={'dxDateBox'}
                        editorOptions={readonlyDateOptions}
                    >
                        <Label text='Contract Start Date' />
                    </Item>

                    <Item
                        dataField={'endDate'}
                        editorType={'dxDateBox'}
                        editorOptions={readonlyDateOptions}
                    >
                        <Label text='Contract End Date' />
                    </Item>

                <Item
                    dataField={'downpaymentreceived'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Down Payment Received' />
                </Item>

                {/* log
                in email */}
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Login Email' />
                </Item>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {downloadFile("contractAgreement")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingContractAgreement
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Download Contract Agreement')
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {downloadFile("pricingAgreement")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingPricingAgreement
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Download Pricing Agreement')
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {downloadFile("evaluationAndServiceAgreement")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingEvaluationAndServiceAgreement
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Download Evaluation and Service Agreement')
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {downloadFile("quotation")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingQuotation
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Download Quotation')
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {downloadFile("po")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingPo
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Download PO')
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>

            <Toast
            visible={toastConfig.isVisible}
            message={toastConfig.message}
            type={toastConfig.type}
            onHiding={onHiding}
            displayTime={2000}
            />
        </form>
    )
}