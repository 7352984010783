import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './PatientLogListDatagrid.css';

export default function PatientLogListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [logs, setLogs] = useState([]);

    const location = useLocation();

    function handleRowClick(e) {
        // const customerToken = location.state.customerToken;
        // const linkedPartner = location.state.linkedPartner;
        // const customerName = location.state.customerName;
        // const customerID = location.state.customerID;
        // const doctorToken = location.state.doctorToken;
        // const doctorName = location.state.doctorName;

        // const patientToken = e.data.userToken;
        // const patientName = e.data.name;

        // navigate('/patient-log', { state: { customerToken: customerToken, linkedPartner: linkedPartner, customerName: customerName, customerID: customerID, doctorToken: doctorToken, doctorName: doctorName, patientToken: patientToken, patientName: patientName } });
    }

    useEffect(() => {
        // console.log(user.token)
        // console.log(user.userToken)
        // console.log(user.accessToken)
        // console.log(location.state.patientToken)

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'vitome/displaypatientlog',
                {
                    patienttoken: location.state.patientToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    console.log(response.data.result)

                    const outputArray = response.data.result[0].map(item => {

                        var datelogTimestamp = new Date(item.logTimestamp);
                        var logTimestampDate = datelogTimestamp.getFullYear() + '-' + (((datelogTimestamp.getMonth()+1) <= 10) ? ('0' + (datelogTimestamp.getMonth()+1)) : (datelogTimestamp.getMonth()+1)) + '-' + (((datelogTimestamp.getDate()) <= 10) ? ('0' + (datelogTimestamp.getDate())) : (datelogTimestamp.getDate()));
                        var logTimestampTime = (((datelogTimestamp.getHours()-8) < 10) ? ('0' + (datelogTimestamp.getHours()-8)) : (datelogTimestamp.getHours()-8)) + ':' + (((datelogTimestamp.getMinutes()) < 10) ? ('0' + (datelogTimestamp.getMinutes())) : (datelogTimestamp.getMinutes())) + ':' + (((datelogTimestamp.getSeconds()) < 10) ? ('0' + (datelogTimestamp.getSeconds())) : (datelogTimestamp.getSeconds()));
    
                        return { category: item.logActivity, logDate: logTimestampDate, logTime: logTimestampTime, logDescription: item.logDescription };
                    });
                    setLogs(outputArray)
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();
    }, [user]);


    return (
        <React.Fragment>
            <DataGrid dataSource={logs}>
                <Column dataField='category' caption='Activity' />
                <Column dataField='logDate' caption='Date' />
                <Column dataField='logTime' caption='Time' />
                <Column dataField='logDescription' caption='Details' />
                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>
        </React.Fragment>
    )

}