import { HomePage, AdminPartnerPage, PartnerInfoPage, PartnerEditPage, PartnerAddPage, PartnerChangePasswordPage, 
    AdminDoctorPage, AdminPatientPage, PatientLogPage, BillingPage, AdminAddPage, EditHistoryPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    // {
    //     path: '/tasks',
    //     element: TasksPage
    // },
    // {
    //     path: '/profile',
    //     element: ProfilePage
    // },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/admin-partner',
        element: AdminPartnerPage
    },
    {
        path: '/partner-info',
        element: PartnerInfoPage
    },
    {
        path: '/partner-edit',
        element: PartnerEditPage
    },
    {
        path: '/partner-add',
        element: PartnerAddPage
    },
    {
        path: '/partner-changepassword',
        element: PartnerChangePasswordPage
    },
    {
        path: '/admin-doctor',
        element: AdminDoctorPage
    },
    {
        path: '/admin-patient',
        element: AdminPatientPage
    },
    {
        path: '/patient-log',
        element: PatientLogPage
    },
    {
        path: '/admin-billing',
        element: BillingPage
    },
    {
        path: '/admin-add',
        element: AdminAddPage
    },
    {
        path: '/edit-history',
        element: EditHistoryPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
